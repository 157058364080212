<template>
  <div id="app">
    <h1 class="title">Normkreativ</h1>
    <div class="is-flex is-justify-content-center">
      <b-radio-button
        v-model="isSwitchedValue"
        :native-value="true"
        class="mb-5 mx-2"
        type="is-dark"
      >
        Klocka
      </b-radio-button>
      <b-radio-button
        v-model="isSwitchedValue"
        :native-value="false"
        class="mb-5 mx-2"
        type="is-dark"
      >
        Data
      </b-radio-button>
    </div>
    <Clock v-if="isSwitchedValue" />
    <Data v-else />
  </div>
</template>

<script>
import Data from './components/Data.vue'
import Clock from './components/Clock.vue'

export default {
  name: 'App',
  components: {
    Data,
    Clock
  },
  data () {
    return {
      isSwitchedValue: true,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
