<template>
  <clock
    size="500px"
    border="none"
    bg="#eeeeee"
  ></clock>
</template>

<script>
  import Clock from 'vue-clock2';
  export default {
    components: { Clock },
    data () {
      return {
        time: ''
      }
    },
  }
</script>

<style lang="scss" global>
.clock {
  .clock-second,
  .clock-minute {
    display: none !important;
  }

  .hour {
    padding-top: 0 !important;

    span {
      background-color: #fff;
      width: 15px;
      height: 20px;
      transform: rotatez(0deg) !important;
    }

    i {
      display: none !important;
    }

    &:nth-child(3n-1) span {
      height: 40px;
    }
  }
}
</style>
