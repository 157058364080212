<template>
  <div class="data">
    <section
      v-for="(data, index) in hsb"
      :key="index"
      class="is-flex is-flex-direction-row is-align-items-baseline is-justify-content-space-between"
    >
      <p>{{ data.name }}</p>
      <b-progress :value="data.mode && data.progress" show-value format="percent"></b-progress>
      <b-field>
        <b-switch
          v-model="data.mode"
          :true-value="true"
          :false-value="false"
          type="is-dark"
        >
        </b-switch>
      </b-field>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Data',
  data() {
    return {
      isSwitchedCustom: 'Yes',
      progress: 40,
      hsb: {
        tv: {
          name: 'Tv',
          progress: 30,
          mode: true,
        },
        computer: {
          name: 'Dator',
          progress: 20,
          mode: true,
        },
        fridge: {
          name: 'Kyl',
          progress: 40,
          mode: true,
        },
        freezer: {
          name: 'Frys',
          progress: 30,
          mode: true,
        },
        oven: {
          name: 'Spis',
          progress: 60,
          mode: true,
        },
      }
    }
  }
}
</script>
<style lang="scss" global>
.data {
  margin: 0 auto;
  width: 500px;

  p {
    text-align: left;
    min-width: 80px;
  }
}

.progress-wrapper {
  .progress {
    width: 300px;
  }
  .progress-value {
    text-align: center;
  }
}
</style>
